import React, { useState } from 'react';
import { ChevronRight, Mail, Phone, MapPin, Clock, ArrowRight, PersonStanding, Users, Heart, Lightbulb, Workflow, BadgeHelp, Sparkles, Menu, X, LucideIcon, Award, Briefcase } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from './components/ui/carousel';
import { Card, CardContent } from './components/ui/card';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';

const TherapyCard = ({ title, description, Icon, link }: {title:string, description: string, Icon: LucideIcon, link: string }) => (
  <div className="bg-white p-6 md:p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-blue-600">
    <div className="w-12 h-12 md:w-16 md:h-16 bg-blue-50 rounded-lg flex items-center justify-center mb-4 md:mb-6">
      <Icon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
    </div>
    <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-3">{title}</h3>
    <p className="text-gray-600 text-sm md:text-base mb-4 leading-relaxed">{description}</p>
    <Link to={link} className="mt-4 flex items-center text-blue-600 hover:text-blue-700 cursor-pointer group">
      <span className="font-medium text-sm md:text-base">En savoir plus</span>
      <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5 transform group-hover:translate-x-1 transition-transform" />
    </Link>
  </div>
);


const Portfolio = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const condensedInfo = {
    formations: [
      "Diplômé d'Etat Infirmier - IFSI Caen",
      "Thérapeute certifiée - IFATC",
      "Membre EFTTA et SFTF"
    ],
    experience: [
      "Secteur sanitaire (2000-2007, pédopsychiatrie)",
      "Secteur social (2007-2020, département)"
    ]
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      {/* Hero Section */}
      <div className="relative bg-gray-900 min-h-screen">
        <img
          src="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1484330378833-c87e53d1a81a?h=1500&w=2000&fit=clip&fm=jpg"
          alt="Hero"
          className="absolute inset-0 w-full h-full object-cover opacity-70"
        />
        <div className="relative max-w-7xl mx-auto px-4 py-20 md:py-32 min-h-screen flex flex-col md:flex-row items-center justify-center md:justify-around gap-8">
          <div className="rounded-full w-32 h-32 md:w-48 md:h-48 overflow-hidden flex items-center justify-center border-4 border-white">
            <img src="https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_320,w_320,f_auto,q_auto/18512139/498685_503667.jpg" alt="Profile" className="w-full h-full object-cover" />
          </div>
          <div className="text-white text-center">
            <h1 className="text-3xl md:text-5xl lg:text-6xl font-medium tracking-tight">
              FABIENNE PEGEOT
            </h1>
            <p className="mt-4 md:mt-6 text-xl md:text-3xl lg:text-4xl max-w-3xl italic">
              Thérapeute systémique de l'individu, de la famille et du couple
            </p>
            <div className="mt-8 md:mt-10">
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Prendre rendez-vous
                <ChevronRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Therapies Section */}
      <section className="py-16 md:py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-semibold text-center mb-4">
            LES THÉRAPIES SYSTÉMIQUES
          </h2>
          <div className="h-1 w-32 md:w-40 bg-blue-600 mx-auto mb-8 md:mb-12"></div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            <TherapyCard
                link='/les-therapies#pour-vous'
              Icon={PersonStanding}
              title="Pour vous"
              description="La thérapie systémique vous permet de comprendre comment vos problèmes personnels sont influencés par les dynamiques familiales et sociales."
            />
            <TherapyCard
                link='/les-therapies#pour-la-famille'
              Icon={Users}
              title="Pour la famille"
              description="Lors des conflits, crises ou bouleversements familiaux, la thérapie systémique donne à la famille une meilleure compréhension des enjeux."
            />
            <TherapyCard
                link='/les-therapies#pour-le-couple'
              Icon={Heart}
              title="Pour le couple"
              description="Lorsque le couple traverse une crise, les outils systémiques favorisent la communication pour aider les couples sur le chemin de la reconstruction."
            />
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 md:py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12">
            <div className="space-y-6 md:space-y-8">
              <div>
                <h2 className="text-3xl md:text-4xl font-semibold mb-4">QUI SUIS-JE ?</h2>
                <div className="h-1 w-20 bg-blue-600"></div>
              </div>
              
              <div className="prose prose-lg text-gray-600 space-y-4">
                <p className="text-sm md:text-base">
                  Mes origines sont en Bretagne, terre natale où j'ai grandi à travers des valeurs, des croyances, des mythes familiaux transmis de génération en génération.
                </p>
                <p className="text-sm md:text-base">
                  Ma famille a été le lieu d'apprentissages, d'expériences, de découvertes, de relations intra et inter-familiales permettant d'aller vers l'extérieur.
                </p>
                <Link to="/qui-suis-je/#mon-histoire" className="mt-4 flex items-center text-blue-600 hover:text-blue-700 cursor-pointer group">
                  <span className="font-medium">Lire plus</span>
                  <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              </div>

            </div>

            <div className="bg-gray-50 rounded-xl p-6 md:p-8 space-y-6">
              <h3 className="text-xl md:text-2xl font-semibold">Formations et Parcours</h3>
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold flex items-center gap-2 mb-2">
                    <Award className="h-5 w-5 text-blue-600" />
                    Formation
                  </h3>
                  <ul className="space-y-1 ml-7">
                    {condensedInfo.formations.map((item, index) => (
                      <li key={index} className="text-sm">{item}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold flex items-center gap-2 mb-2">
                    <Briefcase className="h-5 w-5 text-blue-600" />
                    Expérience
                  </h3>
                  <ul className="space-y-1 ml-7">
                    {condensedInfo.experience.map((item, index) => (
                      <li key={index} className="text-sm">{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <Link to="/qui-suis-je/#mon-parcours" className="mt-4 flex items-center text-blue-600 hover:text-blue-700 cursor-pointer group">
                <span className="font-medium">Lire plus</span>
                <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16 md:py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col">
            <div>
              <h2 className="text-3xl md:text-4xl font-semibold mb-4">CABINET</h2>
              <div className="h-1 w-20 bg-blue-600"></div>
            </div>
            
            <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-10 gap-8">
                <div className="bg-white rounded-xl p-6 md:p-8 shadow-lg space-y-6 w-full lg:w-1/2">
                    {[
                    {
                        Icon: MapPin,
                        title: "Adresse",
                        content: "48 Avenue Émile Zola, 26100 Romans sur Isère"
                    },
                    {
                        Icon: Phone,
                        title: "Téléphone",
                        content: "06 88 46 86 60"
                    },
                    {
                        Icon: Mail,
                        title: "Email",
                        content: "pegeot.fabienne.therapies@gmail.com"
                    },
                    {
                        Icon: Clock,
                        title: "Horaires",
                        content: "Mardi 9h à 19h00 / Mercredi 18h à 20h00 (co-thérapie) Vendredi 9h à 19h00 / Samedi 9h à 13h00"
                    }
                    ].map((item, index) => (
                    <div key={index} className="flex items-start space-x-4">
                        <div className="w-10 h-10 md:w-12 md:h-12 bg-blue-50 rounded-full flex items-center justify-center flex-shrink-0">
                        <item.Icon className="h-5 w-5 md:h-6 md:w-6 text-blue-600" />
                        </div>
                        <div>
                        <h3 className="font-medium text-gray-900">{item.title}</h3>
                        <p className="text-gray-600 text-sm md:text-base">{item.content}</p>
                        </div>
                    </div>
                    ))}
                </div>

                <div className="w-full lg:w-1/2 flex justify-center items-center">
                    <Carousel className="w-56 sm:w-72 md:w-96">
                        <CarouselContent>
                            {Array.from({ length: 3 }).map((_, index) => (
                            <CarouselItem key={index}>
                                <div className="p-1">
                                <Card className='overflow-hidden'>
                                    <CardContent className="flex aspect-square items-center justify-center">
                                        <img 
                                            src={(index + 1) + ".jpeg"} 
                                            className="w-full h-full object-cover"
                                            alt={`Image ${index + 1}`} 
                                        />
                                    </CardContent>
                                </Card>
                                </div>
                            </CarouselItem>
                            ))}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                    </Carousel>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section */}
      <div id="contact" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center">Contact</h2>
            <form className="mt-8 space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Nom complet
                </label>
                <input
                  type="text"
                  id="name"
                  className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Telephone
                </label>
                <input
                  type="text"
                  id="name"
                  className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Portfolio;