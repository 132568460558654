import React from 'react';

const TherapyCards = () => {
  const cards = [
    {
      number: 1,
      title: "Pour vous",
      description: "La thérapie systémique vous permet de comprendre comment vos problèmes personnels sont influencés par les dynamiques familiales et sociales. Elle vous aide à identifier les conflits et à developper des solutions plus durables.",
      imageUrl: "https://media.istockphoto.com/id/1308753258/fr/photo/solitude-adolescentes.jpg?b=1&s=612x612&w=0&k=20&c=GrqArzH-11msCn13tN3lJ2Pk31jrD63Ntv6Ymf4B4Og=",
      altText: "Adolescente en solitude"
    },
    {
      number: 2,
      title: "Pour la famille",
      description: "Lors des conflit, crises ou bouleversements familiaux, la thérapie systémique donne à la famille une meilleure compréhension des enjeux et permet de mieux communiquer. Chacun a ainsi l'espace pour s'exprimer librement.",
      imageUrl: "https://media.istockphoto.com/id/1028900412/fr/photo/fille-enfant-boulevers%C3%A9-choqu%C3%A9s-ou-sennuyer-en-ignorant-les-parents-et-le-fr%C3%A8re.jpg?b=1&s=612x612&w=0&k=20&c=ovfbZQeSdEpL4zh75SLMRWwTCZi5bRM1J0q6UwYOF30=",
      altText: "Famille en situation de conflit"
    },
    {
      number: 3,
      title: "Pour le couple",
      description: "Lorsque le couple traverse une crise, les outils systémiques favorisent la communication pour aider les couples sur le chemin de la reconstruction ou les aident dans la gestion des conflits lors d'une séparation.",
      imageUrl: "https://media.istockphoto.com/id/1454534006/fr/photo/couple-ayant-un-d%C3%A9saccord-%C3%A0-la-maison.jpg?b=1&s=612x612&w=0&k=20&c=-tOfk5kNNu6pW9X1fZkp1NkhVWVJl9Ea0b7BxXccMGM=",
      altText: "Couple en désaccord"
    }
  ];

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <div key={index} className="flex flex-col space-y-6">
            {/* Image */}
            <div className="aspect-square relative overflow-hidden rounded-lg">
              <img
                src={card.imageUrl}
                alt={card.altText}
                className="object-cover w-full h-full"
              />
            </div>
            
            {/* Number Box */}
            <div className="w-12 h-12 border-2 border-gray-700 flex items-center justify-center">
              <span className="text-2xl font-semibold text-gray-700">
                {card.number}
              </span>
            </div>
            
            {/* Content */}
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold text-gray-800">
                {card.title}
              </h2>
              <p className="text-gray-600 leading-relaxed">
                {card.description}
              </p>
            </div>
            
            {/* Connector line (except for last card) */}
            {index < cards.length - 1 && (
              <div className="hidden md:block absolute top-6 left-full w-full border-t-2 border-gray-300" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TherapyCards;