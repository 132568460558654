import { Link } from "react-router-dom"
import { Menu, X } from "lucide-react"
import { useState } from "react";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="fixed top-0 w-full z-50 backdrop-blur-sm bg-gray-50/90">
                <nav className="container mx-auto max-w-6xl px-4 py-4 md:py-5">
                <div className="flex items-center justify-between">
                    <Link to="/" className="text-lg md:text-xl font-semibold">
                    FABIENNE PEGEOT
                    </Link>

                    {/* Mobile Menu Button */}
                    <button
                    className="md:hidden"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                    {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                    </button>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center space-x-7">
                        <Link key="Accueil" to="/" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Accueil
                        </Link>
                        <Link key="Les Thérapies" to="/les-therapies" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Les Thérapies
                        </Link>
                        <Link key="Qui suis-je ?" to="/qui-suis-je" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Qui suis-je ?
                        </Link>
                        <Link key="Blog" to="/blog" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Blog
                        </Link>
                        <Link key="Info contact" to="/contact" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Contact
                        </Link>
                        <Link key="Supervision et APP" to="/supervision" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Supervision et APP
                        </Link>
                    </div>

                </div>

                {/* Mobile Menu */}
                {isMenuOpen && (
                    <div className="md:hidden flex flex-col gap-2 absolute top-full left-0 right-0 bg-white shadow-lg py-4 pl-4">
                        <Link key="Accueil" to="/" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Accueil
                        </Link>
                        <Link key="Les Thérapies" to="/les-therapies" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Les Thérapies
                        </Link>
                        <Link key="Qui suis-je ?" to="/qui-suis-je" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Qui suis-je ?
                        </Link>
                        <Link key="Blog" to="/Blog" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Blog
                        </Link>
                        <Link key="Info contact" to="/contact" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Contact
                        </Link>
                        <Link key="Supervision et APP" to="/supervision" className="text-gray-700 hover:text-blue-600 transition-colors">
                            Supervision et APP
                        </Link>
                    </div>
                )}
                </nav>
            </header>
    )
}