import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { X, Menu, Mail, Phone, Check } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";

export default function Contact() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    
    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const form = e.target;
            const response = await fetch("https://formspree.io/f/mjkvaeoo", {
                method: "POST",
                body: new FormData(form),
                headers: {
                    "Accept": "application/json"
                }
            });
            if (response.ok) {
                navigate("/merci");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    const plans = [
        {
            name: "Thérapie Individuelle",
            price: "60",
            features: ["Durée: 1h00", "En présentiel"],
            popular: false,
            image: "https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1584621126850-0ec04106a7c2",
            alt: "Thérapie individuelle illustration"
        },
        {
            name: "Thérapie Familiale",
            price: "90",
            features: ["Durée : 1h15 à 1h30", "En présentiel", "Possibilité de recevoir en co-thérapie (deux thérapeutes familiaux)", "Un mercredi sur deux"],
            popular: false,
            image: "https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1542216765-7b2793f379a9",
            alt: "Thérapie familiale illustration"
        },
        {
            name: "Thérapie du Couple",
            price: "70",
            features: ["Durée : 1h00 à 1h15", "En présentiel", "Possibilité de recevoir en co-thérapie"],
            popular: false,
            image: "https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1657831669059-bc71d59ace2d?h=960&w=480&fit=clip&fm=jpg",
            alt: "Thérapie de couple illustration"
        }
    ];

    return (
        <>
            <Header />
            <div id="contact" className="py-16 pt-24 bg-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-lg mx-auto">
                        <h2 className="text-3xl font-bold text-gray-900 text-center">Contact</h2>
                        <form
                            onSubmit={handleFormSubmit}
                            action="https://formspree.io/f/mjkvaeoo"
                            method="POST"
                            className="mt-8 space-y-6"
                        >
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Nom complet
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    required
                                    name="nom"
                                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                    Telephone
                                </label>
                                <input 
                                    type="tel" 
                                    id="phone" 
                                    name="phone" 
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    required
                                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    required
                                    name="email"
                                    className="mt-1 block w-full h-10 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    required
                                    rows={4}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold text-gray-900">Mes séances et tarifs</h2>
                    </div>
                    
                    <div className="grid md:grid-cols-3 gap-8 pb-5">
                        {plans.map((plan) => (
                            <div 
                                key={plan.name}
                                className={`rounded-lg shadow-lg bg-white overflow-hidden ${
                                    plan.popular ? 'border-2 border-blue-500' : ''
                                }`}
                            >
                                {plan.popular && (
                                    <span className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg rounded-tr-lg text-sm font-medium">
                                        Populaire
                                    </span>
                                )}
                                
                                <img
                                    src={plan.image}
                                    alt={plan.alt}
                                    className="w-full h-48 object-cover"
                                />
                                
                                <div className="p-8">
                                    <div className="text-center">
                                        <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                                        <div className="mt-4">
                                            <span className="text-4xl font-bold">{plan.price}€</span>
                                            <span className="text-gray-600">/séance</span>
                                        </div>
                                    </div>

                                    <ul className="mt-8 space-y-4">
                                        {plan.features.map((feature) => (
                                            <li key={feature} className="flex items-center">
                                                <Check size={20} className="text-green-500 mr-2" />
                                                <span className="text-gray-600">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}