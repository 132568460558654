import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { X, Menu, Mail, Phone } from "lucide-react";
import Footer from "./Footer";
import Header from "./Header";

export default function Supervision() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Header />
            <div className="relative p-6 space-y-12 flex justify-center items-center pb-20">
                <div className="absolute inset-0 z-0">
                    <img 
                    src="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1581090698603-a8a626ffdc14?h=1500&w=2000&fit=clip&fm=jpg"
                    alt="Background"
                    className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black/40" />
                </div>
      
                <div className="relative z-10 text-white font-medium max-w-6xl">
                    <section className="pt-6 space-y-4">
                        <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                            La supervision d'équipes
                        </h1>
                        <p>
                            Ses objectifs sont l'amélioration des compétences professionnelles, des conditions de travail, de l'ambiance qui y règne, de l'organisation des diverses tâches à accomplir. 
                            <br />
                            Si l'Analyse des Pratiques est principalement centrée sur l'accompagnement de l'usager, la Supervision focalise son regard sur le ou les supervisés et leurs relations. Elle les amène à s'interroger sur leurs valeurs, leurs priorités et leurs objectifs, leurs perceptions, leurs émotions, leurs processus de pensée et leurs modalités de communication. 
                            <br />
                            Elle renforce chez le ou les supervisés la conscience d'eux-mêmes, de leur engagement, de leurs actes et de leurs conséquences, de leurs responsabilités, ainsi que leurs capacités à instaurer des liens constructifs et de qualité. Elle permet aussi de mesurer et de renforcer la capacité à poser les justes limites dans les relations. Elle permet de clarifier ce qui appartient à chacun en favorisant la mise à distance dans les situations complexes. La supervison offre la possibilité de tirer les leçons de l'expérience, de mobiliser de nouvelles options et ressources et d'en intégrer les apprentissages.
                        </p>
                    </section>
                    <section className="pt-6 space-y-4">
                        <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                            Les interventions de supervision d'équipes en Institution
                        </h1>
                        <p>
                            Les interventions se réalisent  généralement au sein même des Institutions, et des différentes équipes.  La mise en place de sessions nécessite une analyse du contexte, une clareté des objectifs poursuivis, l'élaboration d'un cahier des charges et une contractualisation. L'intervention focalise sur la dimension personnelle ou professionnelle, individuelle ou groupale et institutionnelle. 
                            <br />
                            Ces interventions permettront diversement: 
                            <ul className="pl-6">
                                <li>
                                    - Une analyse approfondie des situations vécues.
                                </li>
                                <li>
                                    - L'expression de leurs résonances émotionnelles pour les différents acteurs.
                                </li>
                                <li>
                                    - La médiation de conflits.
                                </li>
                                <li>
                                    - Une fédération des équipes autour de valeurs partagés.
                                </li>
                                <li>
                                    - La mise en conscience, la réflexivité des obstacles et des freins. 
                                </li>
                                <li>
                                    - L'expression des représentations individuelles.
                                </li>
                                <li>
                                    - La mobilisation des ressources individuelles, collectives, institutionnelles.
                                </li>
                                <li>
                                - Une plus grande compréhension des logiques institutionnelles et leurs enjeux systémiques, politiques, financiers, organisationnels, relationnels, opérationnels.
                                </li>
                                <li>
                                - L'écoute des intérêts individuels, collectifs et institutionnels. 
                                </li>
                                <li>
                                - Une professionnalisation des relations de travail. 

                                </li>
                            </ul>
                            Les séances de supervision d'équipe contribuent activement à la synergie des équipes, à l'accroissement de la qualité des relations et à la mise en cohérence des actions. La méthode stratégique résolutive (concept et processus transmis au sein de l'IFATC à Lyon) permettent de co-construire la problématique, d'évaluer les solutions déjà tentées et de générer de manière collaborative (réunion collaborative) de nouvelles options individuelles, collectives et institutionnelles au service de la qualité des relations et du service à l'attention des usagers. Selon les contextes la mise en place de sessions d'Analyse des Pratiques nécessitent une phase préalable de Supervision d'Equipe. 

                            <br />

                            Si vous recherchez un intervenant en supervison ou en analyse des pratiques, je vous laisse prendre contact par l'intermédiaire du site afin que je vous recontacte et envisager un devis.  
                        </p>
                    </section>
                </div>
            </div>

            <Footer />
        </>
    )
}