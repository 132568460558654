import { Mail, Phone } from "lucide-react"

export default function Footer() {
    return(
        <footer className="bg-gray-900">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-white text-lg font-semibold">Fabienne PEGEOT</h3>
                        <p className="mt-2 text-gray-400">Thérapeute systémique de l'individu, de la famille et du couple</p>
                    </div>
                    <div>
                    <h3 className="text-white text-lg font-semibold">Contact</h3>
                    <div className="mt-2 space-y-2">
                        <div className="flex items-center text-gray-400">
                        <Mail className="h-5 w-5" />
                        <span className="ml-2">pegeot.fabienne.therapies@gmail.com</span>
                        </div>
                        <div className="flex items-center text-gray-400">
                        <Phone className="h-5 w-5" />
                        <span className="ml-2">06 88 46 86 60</span>
                        </div>
                    </div>
                    </div>
                    <div>
                        <h3 className="text-white text-lg font-semibold">Horaires</h3>
                        <p className="mt-2 text-gray-400">
                            Mardi 9h à 19h00 /
                            Mercredi 18h à 20h00 (co-thérapie)<br/>
                            Vendredi 9h à 19h00 /
                            Samedi 9h à 13h00
                        </p>
                    </div>
                    <div>
                        <h3 className="text-white text-lg font-semibold">Inscrite à l’Ordre Infirmier</h3>
                        <p className="mt-2 text-gray-400">
                        PEGEOT FABIENNE<br />
                        N° ordinal :2419104<br />
                        N°RPPS :10103492590
                        </p>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-800 pt-8 text-center">
                <p className="text-gray-400">Toutes les prestations du cabinet sont soumises au secret professionnel, en vertu de l’article 226-13 du Code pénal.</p>
                <p className="text-gray-400">© 2024 Fabienne PEGEOT. Tous droits réservés.</p>
                </div>
                </div>
            </footer>
    )
}