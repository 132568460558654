import { BrowserRouter, Routes, Route } from "react-router-dom"
import Portfolio from "./LandingPage"
import Therapies from "./Therapies/Therapies"
import QuiSuisJe from "./QuiSuisJe"
import Contact from "./Contact"
import Supervision from "./Supervision"
import Blog from "./Blog"
import BlogEditor from "./CreateArticles"
import BlogArticle from "./Article"

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<Portfolio />} />
          <Route path="/les-therapies" element={<Therapies />} />
          <Route path="/qui-suis-je" element={<QuiSuisJe />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/supervision" element={<Supervision />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/*" element={<BlogArticle />} />
          <Route path="/blogedito" element={<BlogEditor />} />
        </Routes>
      </BrowserRouter>
      )
}