import { useState } from "react";
import { Mail, Phone, Menu, X, ChevronRight, Briefcase, Award } from "lucide-react"
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";


export default function QuiSuisJe() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            } 
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const detailedInfo = {
      formations: [
        {
          title: "Diplômé d'Etat Infirmier",
          details: "25 ans d'expérience - IFSI Caen",
          certification: "Inscrite à l'Ordre Infirmier"
        },
        {
          title: "Thérapeute de l'individu, du couple et de la famille",
          details: "Formation IFATC",
          certification: "Inscrite à la SFTF et EFTTA",
          logo: true
        }
      ],
      experience: [
        {
          sector: "Sanitaire",
          period: "2000-2007 et actuellement en pédopsychiatrie depuis 4 ans",
        },
        {
          sector: "Sociale",
          period: "2007-2020",
          details: "Département et Protection Maternelle et Infantile (4 ans)"
        }
      ]
    };

    return (
        <>
        <Header />
        <div className="relative bg-gray-900">
          <img
            src="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/f_auto,q_auto,w_4096/unsplashcom/photo-1527579510488-d7352e5315f3?h=1500&w=2000&fit=clip&fm=jpg"
            alt="Hero"
            className="absolute inset-0 w-full h-full object-cover opacity-70"
          />
          <div className="relative max-w-7xl mx-auto px-4 py-20 md:py-32  flex flex-col md:flex-row items-center justify-center md:justify-around gap-8">
            <div className="rounded-full w-32 h-32 md:w-48 md:h-48 overflow-hidden flex items-center justify-center border-4 border-white">
              <img src="https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_320,w_320,f_auto,q_auto/18512139/498685_503667.jpg" alt="Profile" className="w-full h-full object-cover" />
            </div>
            <div className="text-white text-center">
              <h1 className="text-3xl md:text-5xl lg:text-6xl font-medium tracking-tight">
                FABIENNE PEGEOT
              </h1>
              <p className="mt-4 md:mt-6 text-xl md:text-3xl lg:text-4xl max-w-3xl italic">
                Thérapeute systémique de l'individu, de la famille et du couple
              </p>
              <div className="mt-8 md:mt-10">
                <a
                  href="/contact"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Prendre rendez-vous
                  <ChevronRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="mon-histoire" className="max-w-4xl mx-auto p-4 space-y-12 pb-20 pt-20">
          <section className="space-y-4">
              <h2 className="text-3xl font-bold text-blue-800 pb-2 italic">
                Une histoire 
              </h2>
              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                  Mes origines sont en Bretagne, terre natale où j'ai grandi à travers des valeurs, des croyances,  des mythes familiaux transmis de génération en génération. 
                  <br />
                  Ma famille a été le lieu d'apprentissages, d'expériences,  de découvertes, de relations intra et inter-familiales permettant d'aller vers l'extérieur, les autres et ainsi de devenir autonome pour dépasser les différentes étapes de la vie.  
                  <br />
                  Mon choix de quitter le nid familial s'est fait  par l'orientation vers des études supérieures  : le métier d'infirmière. D'autres rencontres sociales, amicales, d'autres environnements, d'autres expériences, d'autres cultures  me permettent de modeler mon identité personnelle, professionnelle et sociale . 
                  <br />
                  Mon parcours personnel évolue en lien avec la construction de mon  projet couple pour ensuite devenir notre projet famille. 
                  <br />
                  L'adapation indispensable au cours du cycle de vie implique des changements , des déménagements, de nouvelles rencontres et des orientations professionnelles. 
                  <br />
                  Les expériences professionnelles et les rencontres significatives m'amènent à vouloir oeuvrer  différemment avec les personnes (enfants, parents, collègues) au sein des structures sanitaires ou  sociales et dans différents contextes : associatif, amical, sociétal. Les changements de contexte amènent la réflexion et le choix de  : devenir thérapeute familial.  
                  <br />
                  Après 4 années de formation  à l'Institut de Formation et Applications des Thérapies de la Communication à Lyon, certifié en tant que Thérapeute de l'individu, du couple et de la Famille, j'exerce en  cabinet  libéral afin de recevoir toutes les personnes qui souhaitent travailler sur les relations, les conflits et envisager un changement dans leur vie actuelle pour trouver un nouvel équilibre, un climat serein, apaisé avec l'objectif de continuer leur chemin de vie : écrire leur histoire. 
                  <br />
                  J'exerce également en qualité d'infirmière en CMP-CATTP, en pédopsychiatrie dans le département de la Drôme , où j'accompagne des familles et des enfants sur des missions groupales.  
              </p>
          </section>
        </div>
        <div id="mon-parcours" className="max-w-4xl mx-auto p-4 space-y-12 pb-20 pt-20">
          <section className="space-y-4">
              <h2 className="text-3xl font-bold text-blue-800 pb-2 italic">
                  Mon parcours
              </h2>
              <div className="flex flex-col md:flex-row justify-between gap-10">
                <div className="space-y-4 w-full md:w-1/2">
                  <h3 className="font-semibold flex items-center gap-2">
                    <Award className="h-5 w-5 text-blue-600" />
                    Formations et Certifications
                  </h3>
                  {detailedInfo.formations.map((item, index) => (
                    <div key={index} className="ml-7 flex justify-between items-center">
                      <div className="space-y-1">
                        <h4 className="font-medium text-base">{item.title}</h4>
                        <p className="text-sm text-gray-600">{item.details}</p>
                        <p className="text-sm text-blue-600">{item.certification}</p>
                      </div>
                      {item.logo && (
                        <div className="flex space-x-5 justify-center items-center">
                          <img src={"https://www.ifatc.com/wp-content/uploads/2023/02/logo.png"} className="size-16"/>
                          <img src={"https://www.sftf.net/wp-content/uploads/2016/03/logo-sftf.png"} className="size-14"/>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                
                <div className="space-y-4 w-full md:w-1/2">
                  <h3 className="font-semibold flex items-center gap-2">
                    <Briefcase className="h-5 w-5 text-blue-600" />
                    Expérience Professionnelle
                  </h3>
                  {detailedInfo.experience.map((item, index) => (
                    <div key={index} className="ml-7 space-y-1">
                      <h4 className="font-medium text-base">{item.sector}</h4>
                      <p className="text-sm text-gray-600">{item.period}</p>
                      <p className="text-sm text-gray-600">{item.details}</p>
                    </div>
                  ))}
                </div>
              </div>
          </section>
        </div>
        
        <Footer />
       </>
    )
}