import { useState } from "react";
import { Link } from "react-router-dom";
import { Mail, Phone, Menu, X } from "lucide-react"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import TherapyCards from "./TherapiesCard";
import Header from "../Header";

export default function Therapies() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            } 
        }else {
            window.scrollTo(0, 0);
        }
    }, [location]);
    

    return (
        <>
            <Header />
            <div className="bg-gray-100 mb-10 pt-24">
                <section className="max-w-6xl mx-auto p-4 py-10 pt-10 space-y-4">
                    <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                        La Thérapie systémique
                    </h1>
                    <p className="text-sm md:text-base text-gray-600 leading-relaxed">" La thérapie systémique permet de comprendre et de transformer les dynamiques relationnelles de chacun, ainsi qu'au sein des familles et des groupes, en abordant les problèmes non pas en tant qu'individus isolés, mais en tant que membres interconnectés d'un contexte".</p>
                </section>
            </div>

            <TherapyCards />
            <div className="max-w-6xl mx-auto p-4 space-y-12 pb-20 pt-10">
                <section id="pour-vous" className="space-y-4">
                    <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                        Pour vous
                    </h1>
                    <h2 className="text-md md:text-xl font-semibold text-gray-700">
                        La Thérapie Systémique Individuelle
                    </h2>
                    <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                    Parler de ce qui nous arrive, que ce soit au niveau professionnel, amoureux, amical, familial est toujours délicat.         
<br/>
Lorsque l’on se sent mal, il est essentiel que nos difficultés ne se manifestent pas en vase clos. Nos émotions, nos
comportements, et même nos souffrances psychologiques sont souvent le reflet d’interactions complexes au sein de notre environnement. C’est ici que la thérapie systémique entre en jeu, en offrant une perspective unique et globale
pour aborder ces enjeux.             
<br/>
Choisir une thérapie systémique lorsqu’on se sent mal, c’est s’offrir la possibilité d’un changement plus profond durable, en
comprenant ses propres mécanismes, ainsi que ceux dans ses relations avec autrui. Ce choix favorise une compréhension plus complète des causes du mal-être, permettant ainsi de mettre en place des solutions adaptées et efficaces.                     </p>
                </section>

                <section id="pour-la-famille" className="pt-20 space-y-4">
                    <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                        Pour la famille 
                    </h1>
                    <h2 className="text-md md:text-xl font-semibold text-gray-700">
                        La Thérapie Familiale Systémique
                    </h2>
                    <p className="text-base md:text-lg font-semibold text-gray-700">"Venir au monde, c'est déjà faire partie d'un système." Bien avant d'arriver sur cette terre, on est déjà imprégnée de l'histoire familiale de nos parents, de la manière dont on a été conçu, de la façon dont la grossesse a été vécue, du contexte et des conditions dans lesquelles on arrive au monde et de la place dans la fratrie et la famille. Tous ces éléments auront déjà forgé une partie de nous-même. Chaque être humain a sa part de singularité dans ce processus. </p>
                    <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                    La thérapie familiale systémique permet de comprendre de quoi et comment sont fait les liens entre tous. 
                    <br />
L'objectif de la thérapie est de prendre en compte le système familial et ses interactions. 
<br />
De voir quels sont les enjeux et quelles sont les difficultés qui persistent dans le temps, aussi bien pour le "patient désigné" que pour la famille. En général, un fonctionnement, même néfaste pour celui qui le produit, a une raison d'être s'il est maintenu dans le temps. 
<br />
En séance, chacun s'exprime comme il veut(ou pas). Aborder la crise dans un espace thérapeutique afin de créer un changement et permettre ainsi à la famille de retrouver sa créativité.
<br />
Chaque demande d'aide est spécifique puisqu'elle tient compte de la famille, de ses fonctionnements, et du moment où elle demande de l'aide. 

                    </p>
                </section>

                <section id="pour-le-couple" className="pt-20 space-y-4">
                    <h1 className="text-3xl font-bold text-blue-800 pb-2 italic">
                        Pour le couple
                    </h1>
                    <h2 className="text-md md:text-xl font-semibold text-gray-700">
                        La Thérapie Systémique de Couple 
                    </h2>
                    <p className="text-sm md:text-base text-gray-600 leading-relaxed">

                    Lorsque la communication est rompue depuis un moment, que vous ne parvenez plus à comprendre votre conjoint ou votre compagne, il est temps d'envisager d'aider votre couple : 
<br />
<ul className="pl-6">
    <li>
    - soit pour permettre à votre couple de reconstruire une passerelle pour renouer la discussion, 
    </li>
    <li>
    - soit pour vous accompagner dans la rupture de la manière la plus facile possible. 
    </li>
</ul>
Cette thérapie favorise la reprise de la communication ou vous accompagne dans une séparation sereine.                     </p>
                </section>
            </div>
            <footer className="bg-gray-900">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                    <h3 className="text-white text-lg font-semibold">Fabienne PEGEOT</h3>
                    <p className="mt-2 text-gray-400">Thérapeute systémique de l'individu, de la famille et du couple</p>
                    </div>
                    <div>
                    <h3 className="text-white text-lg font-semibold">Contact</h3>
                    <div className="mt-2 space-y-2">
                        <div className="flex items-center text-gray-400">
                        <Mail className="h-5 w-5" />
                        <span className="ml-2">pegeot.fabienne.therapies@gmail.com</span>
                        </div>
                        <div className="flex items-center text-gray-400">
                        <Phone className="h-5 w-5" />
                        <span className="ml-2">06 88 46 86 60</span>
                        </div>
                    </div>
                    </div>
                    <div>
                        <h3 className="text-white text-lg font-semibold">Horaires</h3>
                        <p className="mt-2 text-gray-400">
                            Mardi 9h à 19h00 /
                            Mercredi 18h à 20h00 (co-thérapie)<br/>
                            Vendredi 9h à 19h00 /
                            Samedi 9h à 13h00
                        </p>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-800 pt-8 text-center">
                    <p className="text-gray-400">© 2024 Fabienne PEGEOT. Tous droits réservés.</p>
                </div>
                </div>
            </footer>
        </>
    )
}